import { environment } from 'environments/environment';
import { ApplicationConfigDefinition } from '@data/common/ApplicationConfigDefinition';

export const ApplicationConfig: ApplicationConfigDefinition = {
  applicationOwner: 'Apzumi',
  applicationName: 'Mindnumbers',
  version: '0.1.1 (2024-08-19)',
  environmentName: environment.name,
  production: environment.production,
  apiUrl: environment.apiUrl,
  cognito: environment.cognito,
  defaultLanguage: environment.defaultLanguage
};
