<form class="validation-after-submit validation-after-touch ng-submitted ng-invalid">
  <section class="page" class="m-4">
    <section>
      <h1>Switch</h1>

      <div class="form-group">
        <div class="form-check form-switch form-switch-lg">
          <input class="form-check-input" type="checkbox" role="switch">
          <label class="form-check-label">Default switch checkbox input</label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" checked>
          <label class="form-check-label">Checked switch checkbox input</label>
        </div>
      </div>


      <div class="form-group">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" disabled>
          <label class="form-check-label">Disabled switch checkbox input</label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" checked
                 disabled>
          <label class="form-check-label">Disabled checked switch checkbox input</label>
        </div>
      </div>
    </section>

    <section>
      <h1>Checkbox</h1>

      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Default checkbox
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
          <label class="form-check-label" for="flexCheckChecked">
            Checked checkbox
          </label>
        </div>
      </div>
    </section>

    <section>
      <h1>Radio button</h1>

      <div class="form-group">
        <div class="custom-control custom-radio primary">
          <input type="radio" class="custom-control-input" id="anonymous2" name="anonymous2">
          <label class="custom-control-label" for="anonymous2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Ea laborum, veniam. Libero, recusandae voluptate! Aperiam autem consectetur consequuntur delectus eum fugit
            illo itaque necessitatibus, nemo odit quia, quibusdam recusandae, repellendus?</label>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-radio primary">
          <input type="radio" class="custom-control-input" id="anonymous3" name="anonymous2">
          <label class="custom-control-label" for="anonymous3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Animi eius, eos eveniet fugit ipsum molestiae nam nobis placeat provident sunt tempore vel veniam vero.
            Beatae
            dolore incidunt nulla possimus veritatis.</label>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-radio primary">
          <input type="radio" class="custom-control-input ng-invalid" id="anonymous3-invalid" name="anonymous2">
          <label class="custom-control-label" for="anonymous3-invalid">Lorem ipsum dolor sit amet, consectetur
            adipisicing
            elit.
            Animi eius, eos eveniet fugit ipsum molestiae nam nobis placeat provident sunt tempore vel veniam vero.
            Beatae
            dolore incidunt nulla possimus veritatis.</label>

          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-radio primary">
          <input type="radio" class="custom-control-input" id="anonymous4" name="anonymous2" disabled>
          <label class="custom-control-label" for="anonymous4">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Delectus ea, fugit iste laboriosam nam odit quia repudiandae sapiente? Dolorum illo incidunt iusto magni
            nihil, nisi pariatur sapiente sint vitae voluptatem.</label>
        </div>
      </div>
    </section>

    <section>
      <h1>Input</h1>
      <div class="d-flex flex-column gap-3">
        <div class="form-group">
          <label for="input-test-1">Label (normal size)</label>
          <input id="input-test-1" type="text" class="form-control" placeholder="Placeholder">
        </div>

        <div class="form-group">
          <label for="input-test-1-lg">Label (lg)</label>
          <input id="input-test-1-lg" type="text" class="form-control form-control-lg" placeholder="Placeholder">
        </div>

        <div class="form-group">
          <label for="input-test-1-sm">Label (sm)</label>
          <input id="input-test-1-sm" type="text" class="form-control form-control-sm" placeholder="Placeholder">
        </div>

        <div class="form-group">
          <label for="input-test-2">Label</label>
          <input id="input-test-2" type="text" class="form-control" placeholder="Placeholder" value="Some Value">
        </div>

        <div class="form-group">
          <label for="input-test-3">Label</label>
          <input id="input-test-3" type="text" class="form-control ng-invalid" placeholder="Placeholder" value="Error">
          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group">
          <label for="input-test-4">Label</label>
          <input id="input-test-4" type="text" class="form-control" placeholder="Placeholder" value="Disabled"
                 [disabled]="true">
        </div>

        <div class="form-group">
          <label for="input-test-41">Label</label>
          <input id="input-test-41" type="text" class="form-control ng-invalid" placeholder="Placeholder"
                 value="Disabled Error"
                 [disabled]="true">
          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group">
          <label for="input-test-5">Label</label>
          <input id="input-test-5" type="text" class="form-control" placeholder="Placeholder" [disabled]="true">
        </div>
      </div>
    </section>

    <section>
      <h1>Select</h1>

      <div class="d-flex flex-column gap-3">
        <div class="form-group">
          <label for="select-test-1">Label</label>
          <ng-select id="select-test-1" class="" [items]="options" placeholder="Placeholder"></ng-select>
        </div>

        <div class="form-group">
          <label for="select-test-1lg">Label (lg)</label>
          <ng-select id="select-test-1lg" class="ng-select-lg" [items]="options" placeholder="Placeholder"></ng-select>
        </div>

        <div class="form-group">
          <label for="select-test-1sm">Label (sm)</label>
          <ng-select id="select-test-1sm" class="ng-select-sm" [items]="options" placeholder="Placeholder"></ng-select>
        </div>

        <div class="form-group">
          <label for="select-test-2">Label</label>
          <ng-select id="select-test-2" name="select-test-2" class="" [items]="options" placeholder="Placeholder"
                     [clearable]="false"
                     bindValue="value"
                     [(ngModel)]="selectedValue"></ng-select>
        </div>

        <div class="form-group">
          <label for="select-test-3">Label</label>
          <ng-select id="select-test-3" name="select-test-3" class="ng-invalid" [items]="options"
                     placeholder="Placeholder"
                     bindValue="value"></ng-select>
          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group">
          <label for="select-test-4">Label</label>
          <ng-select id="select-test-4" class="" [items]="options" placeholder="Placeholder"
                     [clearable]="false" disabled></ng-select>
        </div>

        <div class="form-group">
          <label for="select-test-31">Label</label>
          <ng-select id="select-test-31" name="select-test-31" class="ng-invalid" [items]="options"
                     placeholder="Placeholder"
                     [disabled]="true"
                     bindValue="value"
                     [(ngModel)]="selectedValue"></ng-select>
          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group">
          <label for="select-test-32">Label</label>
          <ng-select id="select-test-32" name="select-test-32" class="ng-invalid" [items]="options"
                     placeholder="Placeholder"
                     [disabled]="true"
                     bindValue="value"
                     [(ngModel)]="selectedValue"></ng-select>
          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="form-group">
          <label for="select-test-323">Label</label>
          <ng-select id="select-test-323" name="select-test-323" class="ng-invalid" [items]="options"
                     placeholder="Placeholder"
                     [disabled]="true"
                     [(ngModel)]="nonSelectedValue"
                     bindValue="value"></ng-select>
          <div class="text-danger">
            <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <h1>Button</h1>

      <div class="py-2">
        <button class="btn btn-primary">Primary button</button>
      </div>

      <div class="py-2">
        <button class="btn btn-outline-primary">Primary outline button</button>
      </div>

      <!--    with icons-->
      <div class="py-2">
        <button class="btn btn-primary">
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary button</span>
          </div>
        </button>
      </div>

      <div class="py-2">
        <button class="btn btn-outline-primary">
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary outline button</span>
          </div>
        </button>
      </div>

      <h4 class="mt-2">Button size</h4>
      <div class="py-2">
        <button class="btn btn-primary">
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary button normal size</span>
          </div>
        </button>
      </div>

      <div class="py-2">
        <button class="btn btn-primary btn-lg">
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary button large size</span>
          </div>
        </button>
      </div>

      <div class="py-2">
        <button class="btn btn-primary btn-sm">
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary button small size</span>
          </div>
        </button>
      </div>

      <h4 class="mt-2">Disabled</h4>
      <div class="py-2">
        <button class="btn btn-primary" disabled>Primary button</button>
      </div>

      <div class="py-2">
        <button class="btn btn-outline-primary" disabled>Primary outline button</button>
      </div>

      <div class="py-2">
        <button class="btn btn-outline-primary" disabled>
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary outline button with icon disabled</span>
          </div>
        </button>
      </div>

      <div class="py-2">
        <button class="btn btn-primary" disabled>
          <div class="d-flex align-items-center gap-2">
            <i-tabler name="users" class="icon"></i-tabler>
            <span>Primary button with icon disabled</span>
          </div>
        </button>
      </div>

    </section>

    <section>
      <h1>Icons</h1>
      <div class="d-flex flex-column">
        <i-tabler name="users" class="icon"></i-tabler>
      </div>
    </section>


    <section>
      <h1>Colors</h1>

      <div class="d-flex flex-column gap-2">
        <div class="d-flex gap-2">
          <div class="color bg-primary-300"></div>
          <div class="color bg-primary-400"></div>
          <div class="color bg-primary-500"></div>
          <div class="color bg-primary-600"></div>
          <div class="color bg-primary-700"></div>
          <div class="color bg-primary-800"></div>
          <div class="color bg-primary-900"></div>
        </div>

        <div class="d-flex gap-2">
          <div class="color bg-secondary-300"></div>
          <div class="color bg-secondary-400"></div>
          <div class="color bg-secondary-500"></div>
          <div class="color bg-secondary-600"></div>
          <div class="color bg-secondary-700"></div>
          <div class="color bg-secondary-800"></div>
          <div class="color bg-secondary-900"></div>
        </div>

        <div class="d-flex gap-2">
          <div class="color bg-gray-300"></div>
          <div class="color bg-gray-400"></div>
          <div class="color bg-gray-500"></div>
          <div class="color bg-gray-600"></div>
          <div class="color bg-gray-700"></div>
          <div class="color bg-gray-800"></div>
          <div class="color bg-gray-900"></div>
        </div>

        <div class="d-flex gap-2">
          <div class="color bg-green-300"></div>
          <div class="color bg-green-400"></div>
          <div class="color bg-green-500"></div>
          <div class="color bg-green-600"></div>
          <div class="color bg-green-700"></div>
          <div class="color bg-green-800"></div>
          <div class="color bg-green-900"></div>
        </div>

        <div class="d-flex gap-2">
          <div class="color bg-red-300"></div>
          <div class="color bg-red-400"></div>
          <div class="color bg-red-500"></div>
          <div class="color bg-red-600"></div>
          <div class="color bg-red-700"></div>
          <div class="color bg-red-800"></div>
          <div class="color bg-red-900"></div>
        </div>
      </div>
    </section>
  </section>
</form>
