import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'app/store/auth/auth.selectors';
import { map } from 'rxjs/operators';

export const authGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(AuthSelectors.isAuthenticated).pipe(
    map((isLogged: boolean) => isLogged || router.parseUrl('/auth/login'))
  );
};