export namespace LoaderActions {
  export class StartLoading {
    static readonly type = '[Loader] Start loading';

    constructor(public key: string) {

    }
  }

  export class StopLoading {
    static readonly type = '[Loader] Stop loading';

    constructor(public key: string) {

    }
  }
}