import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { NavigationExtras, Params } from '@angular/router';
import { NavigationActions } from 'app/store/navigation/navigation.actions';

@Component({
  selector: 'app-back',
  template: `
    <button type="button" [ngClass]="setClass" (click)="back()">
      <div class="d-flex align-items-center gap-2">
        <i-tabler name="chevron-left" class="icon"></i-tabler>
        <span>{{ text | translate }}</span>
      </div>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackComponent {
  @Input() defaultPath?: string[];

  @Input() setClass: string = 'btn btn-link btn-link-primary px-0';

  @Input() queryParams?: Params;

  @Input() extras?: NavigationExtras;

  @Input() text: string = 'COMMON.BACK';

  constructor(private store: Store) {
  }

  public back(): void {
    this.store.dispatch(new NavigationActions.Back(this.defaultPath, this.queryParams, this.extras));
  }
}
