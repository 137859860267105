import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { ApplicationConfig } from '@config/application.config';
import { Constant } from '@common/constant';
import { Store } from '@ngxs/store';
import { LoaderActions } from 'app/store/loader/loader.actions';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private readonly store: Store) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, urlWithParams, headers } = request;

    if (url.startsWith(ApplicationConfig.apiUrl) || url.startsWith('http://localhost:3000')) {
      if (!headers.has(Constant.HTTP_SILENT_MODE)) {
        const key: string = `${ uuidv4() }_${ urlWithParams }`;

        this.store.dispatch(new LoaderActions.StartLoading(key));

        return next.handle(request)
          .pipe(
            finalize(() => this.store.dispatch(new LoaderActions.StopLoading(key)))
          );
      }

      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
