import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { ApplicationConfig } from '@config/application.config';
import { TokenDTO } from '@data/auth/TokenDTO';
import { AuthErrorCode } from '@data/auth/AuthErrorCode';
import { AuthProvider } from '@data/auth/AuthProvider';
import { finalize, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { LoaderActions } from 'app/store/loader/loader.actions';

@Injectable()
export class CognitoAuthProvider implements AuthProvider {
  private cognitoUser: CognitoUser;

  constructor(private readonly store: Store) {
  }

  public login(email: string, password: string): Observable<TokenDTO> {
    const authenticationDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    this.cognitoUser = this.getCognitoUser(email);

    this.setLoader(true, 'login');


    return new Observable<TokenDTO>(observer => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => {
          observer.next(this.buildToken(session, email));
          observer.complete();
        },
        onFailure: (error: unknown) => observer.error(error),
        newPasswordRequired: () => observer.error(new Error(AuthErrorCode.NEW_PASSWORD_REQUIRED))
      });
    })
      .pipe(
        finalize(() => this.setLoader(false, 'login'))
      );
  }

  public setPassword(email: string, newPassword: string): Observable<TokenDTO> {
    this.setLoader(true, 'setPassword');


    return new Observable<TokenDTO>(observer => {
      this.cognitoUser.completeNewPasswordChallenge(newPassword, undefined, {
        onSuccess: (session: CognitoUserSession) => {
          observer.next(this.buildToken(session, email));
          observer.complete();
        },
        onFailure: (error: unknown) => observer.error(error)
      });
    })
      .pipe(
        finalize(() => this.setLoader(false, 'setPassword'))
      );
  }

  public forgotPassword(email: string): Observable<void> {
    this.cognitoUser = this.getCognitoUser(email);
    this.setLoader(true, 'forgotPassword');


    return new Observable<void>(observer => {
      this.cognitoUser.forgotPassword({
        onSuccess: () => {
          observer.next();
          observer.complete();
        },
        onFailure: (error: unknown) => observer.error(error)
      });
    })
      .pipe(
        finalize(() => this.setLoader(false, 'forgotPassword'))
      );
  }

  public resendVerificationCode(email: string): Observable<void> {
    this.cognitoUser = this.getCognitoUser(email);
    this.setLoader(true, 'resendVerificationCode');


    return new Observable<void>(observer => {
      this.cognitoUser.resendConfirmationCode(undefined);

      observer.next();
      observer.complete();
    })
      .pipe(
        finalize(() => this.setLoader(false, 'resendVerificationCode'))
      );
  }

  public confirmPassword(email: string, verificationCode: string, password: string): Observable<void> {
    this.cognitoUser = this.getCognitoUser(email);
    this.setLoader(true, 'confirmPassword');


    return new Observable<void>(observer => {
      this.cognitoUser.confirmPassword(verificationCode, password, {
        onSuccess: () => {
          observer.next();
          observer.complete();
        },
        onFailure: (error: unknown) => observer.error(error)
      });
    })
      .pipe(
        finalize(() => this.setLoader(false, 'confirmPassword'))
      );
  }

  public refresh(token: TokenDTO): Observable<TokenDTO> {

    return new Observable<TokenDTO>(observer => {
      let cognitoUser: CognitoUser = this.getCognitoUser(token.email);

      cognitoUser?.refreshSession(
        new CognitoRefreshToken({ RefreshToken: token.refreshToken }),
        (error: any, session: CognitoUserSession) => {
          if (!error) {
            observer.next(this.buildToken(session, token.email));
            observer.complete();
          } else {
            observer.error(error);
          }
        });
    });
  }

  private getCognitoUser(email: string): CognitoUser {
    return new CognitoUser({
      Username: email,
      Pool: this.getPool()
    });
  }

  private getPool(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: ApplicationConfig.cognito.userPoolId,
      ClientId: ApplicationConfig.cognito.userPoolWebClientId
    });
  }

  private buildToken(session: CognitoUserSession, email: string): TokenDTO {
    const token: TokenDTO = new TokenDTO();

    token.accessToken = session.getIdToken().getJwtToken();
    token.refreshToken = session.getRefreshToken().getToken();
    token.email = email;

    return token;
  }

  private setLoader(value: boolean, key: string): void {
    const action = value ? new LoaderActions.StartLoading(key) : new LoaderActions.StopLoading(key);

    this.store.dispatch(action);
  }
}

