import { LanguageVersion } from '@data/common/LanguageVersion';
import { EnvironmentType } from '@data/common/EnvironmentType';

export const environment = {
  name: EnvironmentType.DEV,
  production: false,
  apiUrl: 'https://api.mn-dev.apzumi.dev/api',
  defaultLanguage: LanguageVersion.EN,
  cognito: {
    userPoolId: 'us-east-1_CTG8fUoMh',
    userPoolWebClientId: '1nba7gh9b4agpdaupra2qp0njg',
    region: 'us-east-1'
  }
};
