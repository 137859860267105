import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateConfig } from 'app/config/translate.config';
import { AppLanguageInterceptor } from '@shared/interceptor/app-language.interceptor';
import { AppVersionInterceptor } from '@shared/interceptor/app-version.interceptor';
import { AppTimezoneInterceptor } from '@shared/interceptor/app-timezone.interceptor';
import { ErrorInterceptor } from '@shared/interceptor/error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { StorageModule } from '@ngx-pwa/local-storage';
import StorageConfig from '@config/storage.config';
import { AccessTokenInterceptor } from '@shared/interceptor/access-token.interceptor';
import { RefreshTokenInterceptor } from '@shared/interceptor/refresh-token.interceptor';
import { DatePipe, registerLocaleData } from '@angular/common';
import { LoaderInterceptor } from '@shared/interceptor/loader.interceptor';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { getDatepickerConfig } from '@config/datepicker.config';
import { NgxsModule, NoopNgxsExecutionStrategy, Store } from '@ngxs/store';
import { AuthState } from 'app/store/auth/auth.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NavigationState } from 'app/store/navigation/navigation.state';
import { LayoutModule } from '@core/layout/layout.module';
import NgxsStorageConfig from '@config/ngxs-storage.config';
import ToastrConfig from '@config/toastr.config';
import NgxSpinnerConfig from '@config/ngx-spinner.config';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ApplicationState } from 'app/store/application/application.state';
import angularLocaleEn from '@angular/common/locales/en';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import MessageFormatConfig from '@config/message-format.config';
import { LanguageVersion } from '@data/common/LanguageVersion';
import { ApplicationConfig } from '@config/application.config';
import { LocaleId } from '@data/common/LocalId';
import { AUTH_PROVIDER } from '@shared/common/injector-token';
import { CognitoAuthProvider } from '@shared/provider/cognito-auth-provider.service';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { LoaderState } from 'app/store/loader/loader.state';

registerLocaleData(angularLocaleEn, LanguageVersion.EN);

defineLocale('en-gb', enGbLocale);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    LayoutModule,
    StorageModule.forRoot(StorageConfig),
    TranslateModule.forRoot(TranslateConfig),
    AngularSvgIconModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    ToastrModule.forRoot(ToastrConfig),
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgxsModule.forRoot([
      ApplicationState,
      AuthState,
      NavigationState,
      LoaderState
    ], {
      developmentMode: !ApplicationConfig.production,
      executionStrategy: NoopNgxsExecutionStrategy
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: ApplicationConfig.production
    }),
    NgxsResetPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(NgxsStorageConfig),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: ApplicationConfig.production
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxSpinnerModule.forRoot(NgxSpinnerConfig),
    FormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useClass: LocaleId, deps: [ Store ] },
    { provide: AUTH_PROVIDER, useClass: CognitoAuthProvider },
    { provide: NgSelectConfig, useValue: { disableVirtualScroll: true, openOnEnter: false } },
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppLanguageInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: LoaderInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppVersionInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppTimezoneInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: ErrorInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AccessTokenInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: RefreshTokenInterceptor },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: MessageFormatConfig },
    DatePipe,
    TranslatePipe
  ],
  exports: [],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor() {
  }
}
