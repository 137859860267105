import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofActionCompleted, ofActionSuccessful, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { debounceTime, fromEvent, merge, Subject, takeUntil } from 'rxjs';
import { ApplicationConfig } from '@config/application.config';
import { delay, distinctUntilChanged, filter } from 'rxjs/operators';
import { AuthActions } from 'app/store/auth/auth.actions';
import { StateResetAll } from 'ngxs-reset-plugin';
import { AuthState } from 'app/store/auth/auth.state';
import { LoaderState } from 'app/store/loader/loader.state';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationActions } from 'app/store/application/application.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private readonly router: Router,
              private readonly store: Store,
              private readonly spinner: NgxSpinnerService,
              private readonly actions: Actions) {
  }

  ngOnInit() {
    merge(
      this.actions.pipe(
        ofActionSuccessful(AuthActions.Login)
      ),
      this.actions.pipe(
        ofActionSuccessful(AuthActions.SetPassword)
      )
    ).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.router.navigate([ '/' ]));

    this.actions.pipe(
      ofActionCompleted(AuthActions.Logout),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.router.navigate([ '/auth/login' ])
        .then(() => this.store.dispatch(new StateResetAll(AuthState)));
    });

    fromEvent<StorageEvent>(window, 'storage')
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event: StorageEvent) => event.key.startsWith(ApplicationConfig.applicationName)),
        debounceTime(300)
      )
      .subscribe(() => window.location.reload());

    this.store.select(LoaderState.uploading).pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
      delay(0)
    ).subscribe((loading: boolean) => loading ? this.spinner.show('main') : this.spinner.hide('main'));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  @HostListener('window:resize', [ '$event' ])
  onResize() {
    this.store.dispatch(new ApplicationActions.SetWindowSize(window.innerWidth));
  }
}
