import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StyleguideComponent } from 'app/view/styleguide/styleguide.component';
import { PreAuthLayoutComponent } from '@core/layout/component/pre-auth-layout/pre-auth-layout.component';
import { MainLayoutComponent } from '@core/layout/component/main-layout/main-layout.component';
import { AccountType } from '@shared/data/auth/AccountType';
import { authGuard } from '@shared/guard/auth.guard';
import { authUserTypeMatchGuard } from '@shared/guard/auth-user-type-match.guard';

const routes: Routes = [
  { path: 'styleguide', component: StyleguideComponent },
  {
    path: 'auth', component: PreAuthLayoutComponent,
    loadChildren: () => import('app/view/auth/auth-page.module').then(m => m.AuthPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('app/view/error/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '', component: MainLayoutComponent, canActivate: [ authGuard ],
    children: [
      {
        path: '',
        canMatch: [ authUserTypeMatchGuard(AccountType.DOCTOR) ],
        loadChildren: () => import('app/view/doctor/doctor-role.module').then(m => m.DoctorRoleModule)
      },
      {
        path: '',
        canMatch: [ authUserTypeMatchGuard(AccountType.SUPER_ADMIN) ],
        loadChildren: () => import('app/view/super-admin/super-admin-role.module').then(m => m.SuperAdminRoleModule)
      }
    ]
  },
  { path: '**', redirectTo: 'error/not-found' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
