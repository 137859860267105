import { LanguageVersion } from '@data/common/LanguageVersion';

export namespace ApplicationActions {
  const prefix = '[Application]';

  export class ChangeLanguage {
    static readonly type = `${ prefix } Change language`;

    constructor(public language: LanguageVersion) {

    }
  }

  export class ShowSideMenu {
    static readonly type = `${ prefix } Show side menu`;

    constructor() {

    }
  }

  export class HideSideMenu {
    static readonly type = `${ prefix } Hide side menu`;

    constructor() {

    }
  }


  export class SetWindowSize {
    static readonly type = `${ prefix } Set window size`;

    constructor(public size: number) {

    }
  }

  export class InitMenu {
    static readonly type = `${ prefix } Initialize menu`;

    constructor() {

    }
  }
}