import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ApplicationConfig } from '@config/application.config';
import { Constant } from '@common/constant';
import { ApplicationSelectors } from 'app/store/application/application.selectors';
import { LanguageVersion } from '@data/common/LanguageVersion';

@Injectable()
export class AppLanguageInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method && (request.url?.indexOf(ApplicationConfig.apiUrl) > -1)) {
      const currentLanguage: LanguageVersion = this.store.selectSnapshot(ApplicationSelectors.language);
      const appLanguageRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          [Constant.HTTP_APP_LANGUAGE_HEADER]: currentLanguage
        }
      });

      return next.handle(appLanguageRequest);
    } else {
      return next.handle(request);
    }
  }
}
