import { Selector } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from './application.state';
import { ApplicationConfig } from '@config/application.config';

export class ApplicationSelectors {
  @Selector([ ApplicationState ])
  static isMobileMode(state: ApplicationStateModel) {
    return state.windowSize < 1440;
  }

  @Selector([ ApplicationState ])
  static language(state: ApplicationStateModel) {
    return state.language;
  }

  @Selector([ ApplicationState ])
  static sideMenu(state: ApplicationStateModel) {
    return state.sideMenuVisibility;
  }

  @Selector([ ApplicationState ])
  static menuItems(state: ApplicationStateModel) {
    return state.menu;
  }

  @Selector([ ApplicationState ])
  static appVersion() {
    return ApplicationConfig.version.substring(0, ApplicationConfig.version.lastIndexOf(' '));
  }
}