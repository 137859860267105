import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'app/store/auth/auth.selectors';
import { AuthActions } from 'app/store/auth/auth.actions';
import { TokenDTO } from '@data/auth/TokenDTO';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshingToken = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && [ 401 ].includes(error.status)) {
          if (this.refreshingToken) {
            return this.refreshTokenSubject
              .pipe(
                filter((result) => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))
              );
          } else {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return this.store.dispatch(AuthActions.RefreshToken)
              .pipe(
                switchMap(() => {
                  this.refreshingToken = false;
                  this.refreshTokenSubject.next(true);

                  return next.handle(this.addAuthenticationToken(request));
                }),

                catchError((catchedError) => {
                  this.refreshingToken = false;
                  this.refreshTokenSubject.next(true);

                  return throwError(catchedError);
                })
              );
          }
        }

        return throwError(error);
      }));
  }

  private addAuthenticationToken(request) {
    const token: TokenDTO = this.store.selectSnapshot(AuthSelectors.token);

    return !token ? request : request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token.accessToken
      }
    });
  }
}
