import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoaderActions } from 'app/store/loader/loader.actions';
import { append, patch, removeItem } from '@ngxs/store/operators';

type LoaderStateModel = {
  activeLoaders: string[]
};

@State<LoaderStateModel>({
  name: 'loader',
  defaults: {
    activeLoaders: []
  }
})
@Injectable()
export class LoaderState {
  @Selector([ LoaderState ])
  static uploading(state: LoaderStateModel): boolean {
    return state.activeLoaders.length > 0;
  }

  @Action(LoaderActions.StartLoading)
  public startLoading(ctx: StateContext<LoaderStateModel>, action: LoaderActions.StartLoading) {
    ctx.setState(
      patch({
        activeLoaders: append([ action.key ])
      })
    );
  }

  @Action(LoaderActions.StopLoading)
  public stopLoading(ctx: StateContext<LoaderStateModel>, action: LoaderActions.StopLoading) {
    ctx.setState(
      patch({
        activeLoaders: removeItem<string>(name => name === action.key)
      })
    );
  }
}