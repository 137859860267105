import { Store } from '@ngxs/store';
import { ApplicationSelectors } from 'app/store/application/application.selectors';
import { LanguageVersion } from '@data/common/LanguageVersion';

export class LocaleId extends String {
  constructor(private readonly store: Store) {
    super();
  }

  public toString(): string {
    const language: LanguageVersion = this.store.selectSnapshot(ApplicationSelectors.language);
    return language;
  }

  public valueOf(): string {
    return this.toString();
  }
}