import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ApplicationSelectors } from 'app/store/application/application.selectors';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [ './main-layout.component.scss' ]
})
export class MainLayoutComponent {
  public sideMenuVisibility$: Observable<boolean> = this.store.select(ApplicationSelectors.sideMenu);

  constructor(private readonly store: Store) {
  }
}
