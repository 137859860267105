import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MenuItem } from '@data/common/MenuItem';
import { ApplicationSelectors } from 'app/store/application/application.selectors';
import { ApplicationActions } from 'app/store/application/application.actions';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss' ]
})
export class SideMenuComponent implements OnInit {
  public menuItems$: Observable<MenuItem[]> = this.store.select(ApplicationSelectors.menuItems);

  public appVersion$: Observable<string> = this.store.select(ApplicationSelectors.appVersion);

  @Input() public menuCollapse: boolean = false;

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new ApplicationActions.InitMenu());
  }
}
