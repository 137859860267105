<div class="modal-header">
  <div class="icon" [ngClass]="iconClass ?? 'bg-primary-900 text-primary'">
    <i-tabler class="i-tabler-xl" [name]="icon ?? 'question-mark'"></i-tabler>
  </div>
</div>

<div class="modal-body d-flex gap-4 flex-column">
  <ng-container [ngTemplateOutlet]="titleTemplate || defaultTitleTemplate"></ng-container>
  <ng-container [ngTemplateOutlet]="descriptionTemplate || defaultDescriptionTemplate"></ng-container>
</div>

<div class="d-flex flex-column modal-footer">
  <button type="button"
          class="btn btn-primary btn-lg w-100" *ngIf="showConfirmButton"
          (click)="ok(true)">{{ confirmButtonText | translate:data }}
  </button>

  <button type="button"
          class="btn btn-link btn-link-primary btn-lg w-100" *ngIf="showCancelButton"
          (click)="close()">{{ cancelButtonText | translate:data }}
  </button>
</div>

<!--Templates-->
<ng-template #defaultTitleTemplate>
  <h5 class="text-center fw-bold" [innerHtml]="title | translate: data"></h5>
</ng-template>

<ng-template #defaultDescriptionTemplate>
  <span class="text-center" [innerHtml]="description | translate: data"></span>
</ng-template>
