import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CastToTypePipe } from './pipe/to-type.pipe';
import { ReadOnlyEntryComponent } from '@shared/component/read-only-entry/read-only-entry.component';
import { BackComponent } from '@shared/component/back/back.component';
import { RouterModule } from '@angular/router';
import { ConfirmationModalComponent } from '@shared/component/modal/confirmation/confirmation-modal.component';
import { LogoComponent } from './component/logo/logo.component';
import { ConcatPipe } from './pipe/concat.pipe';
import { ReplaceEmptyPipe } from './pipe/replace-empty.pipe';
import { LinkifyPipe } from '@shared/pipe/linkify.pipe';
import { ConcatEnumPipe } from '@shared/pipe/concat-enum.pipe';
import { FullNamePipe } from '@shared/pipe/full-name.pipe';
import { DurationPipe } from '@shared/pipe/duration.pipe';
import { ToArrayPipe } from '@shared/pipe/to-array.pipe';
import { IncludePipe } from '@shared/pipe/include.pipe';
import { TranslateOrDefaultPipe } from '@shared/pipe/translate-or-default.pipe';
import { HideIfElementEmptyDirective } from './directive/hide-if-element-empty.directive';
import { InArrayPipe } from '@shared/pipe/in-array.pipe';
import { InputCurrencyDirective } from '@shared/directive/input-currency.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { PrefixPipe } from '@shared/pipe/prefix.pipe';
import { BooleanPipe } from '@shared/pipe/boolean.pipe';
import { ValidationHandlerComponent } from '@shared/component/validation-handler/validation-handler.component';
import { InputPasswordToggleDirective } from '@shared/directive/input-password-toggle.directive';
import { CanPerformActionDirective } from '@shared/directive/can-perform-action.directive';
import { DragDropFileDirective } from '@shared/directive/drag-drop-file.directive';
import { FocusTrapModule } from 'ngx-bootstrap/focus-trap';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { SideMenuComponent } from '@shared/component/side-menu/side-menu.component';
import { TopBarComponent } from '@shared/component/top-bar/top-bar.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
    // Components
    ReadOnlyEntryComponent,
    BackComponent,
    SideMenuComponent,
    TopBarComponent,
    LogoComponent,
    ValidationHandlerComponent,
    // Modals
    ConfirmationModalComponent,
    // Pipes
    CastToTypePipe,
    TranslateOrDefaultPipe,
    ConcatPipe,
    LinkifyPipe,
    ReplaceEmptyPipe,
    ConcatEnumPipe,
    FullNamePipe,
    DurationPipe,
    ToArrayPipe,
    IncludePipe,
    InArrayPipe,
    PrefixPipe,
    BooleanPipe,
    // Directives
    InputCurrencyDirective,
    HideIfElementEmptyDirective,
    InputPasswordToggleDirective,
    CanPerformActionDirective,
    DragDropFileDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    FocusTrapModule,
    TablerIconsModule.pick(TablerIcons),
    CollapseModule,
    BsDropdownModule
  ],
  exports: [
    CommonModule,
    TopBarComponent,
    SideMenuComponent,
    RouterModule,
    TablerIconsModule,
    TranslateModule,
    AngularSvgIconModule,
    CastToTypePipe,
    ConcatPipe,
    ReadOnlyEntryComponent,
    BackComponent,
    LogoComponent,
    ReplaceEmptyPipe,
    LinkifyPipe,
    ConcatEnumPipe,
    FullNamePipe,
    DurationPipe,
    ToArrayPipe,
    IncludePipe,
    TranslateOrDefaultPipe,
    HideIfElementEmptyDirective,
    InArrayPipe,
    InputCurrencyDirective,
    PrefixPipe,
    BooleanPipe,
    ValidationHandlerComponent,
    InputPasswordToggleDirective,
    CanPerformActionDirective,
    DragDropFileDirective
  ],
  providers: [
    DecimalPipe,
    FullNamePipe
  ]
})
export class SharedModule {
}
