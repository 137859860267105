import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '@config/application.config';
import { Constant } from '@common/constant';
import { Store } from '@ngxs/store';
import { ApplicationSelectors } from 'app/store/application/application.selectors';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {

  constructor(private readonly store: Store) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method && (request.url?.indexOf(ApplicationConfig.apiUrl) > -1)) {
      const appVersionRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          [Constant.HTTP_APP_VERSION_HEADER]: this.store.selectSnapshot(ApplicationSelectors.appVersion)
        }
      });

      return next.handle(appVersionRequest);
    } else {
      return next.handle(request);
    }
  }
}
