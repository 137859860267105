import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ApplicationActions } from 'app/store/application/application.actions';
import { ApplicationConfig } from '@config/application.config';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LanguageVersion } from '@data/common/LanguageVersion';
import { MenuItem } from '@data/common/MenuItem';
import { AuthSelectors } from 'app/store/auth/auth.selectors';
import { AccountType } from '@data/auth/AccountType';
import DoctorMenu from 'app/view/doctor/constant/menu';
import SuperAdminMenu from 'app/view/super-admin/constant/menu';

export interface ApplicationStateModel {
  language?: LanguageVersion;

  menu?: MenuItem[],

  sideMenuVisibility: boolean;

  windowSize?: number;
}

@State<ApplicationStateModel>({
  name: 'application',
  defaults: {
    sideMenuVisibility: true,
    windowSize: window.innerWidth
  }
})
@Injectable()
export class ApplicationState implements NgxsOnInit {
  constructor(private readonly translateService: TranslateService,
              private readonly store: Store,
              private readonly bsLocaleService: BsLocaleService) {
  }

  ngxsOnInit(ctx: StateContext<ApplicationStateModel>) {
    this.setDefaultLanguage(ctx);
  }

  @Action(ApplicationActions.ChangeLanguage)
  public changeLanguage(ctx: StateContext<ApplicationStateModel>, action: ApplicationActions.ChangeLanguage) {
    const { language } = action;

    setTimeout(() => {
      this.translateService.use(language);
      this.bsLocaleService.use(language);
    });

    ctx.patchState({ language });
  }

  @Action(ApplicationActions.InitMenu)
  public updateMenu(ctx: StateContext<ApplicationStateModel>) {
    const accountType: AccountType = this.store.selectSnapshot(AuthSelectors.type);

    switch (accountType) {
      case AccountType.DOCTOR:
        ctx.patchState({ menu: DoctorMenu });
        break;
      case AccountType.ORGANIZATION:
        break;
      case AccountType.SUPER_ADMIN:
        ctx.patchState({ menu: SuperAdminMenu });
        break;
    }
  }

  @Action(ApplicationActions.SetWindowSize)
  public setWindowSize(ctx: StateContext<ApplicationStateModel>, action: ApplicationActions.SetWindowSize) {
    ctx.patchState({ windowSize: action.size });
  }

  @Action(ApplicationActions.ShowSideMenu)
  public showSideMenu(ctx: StateContext<ApplicationStateModel>) {
    ctx.patchState({ sideMenuVisibility: true });
  }

  @Action(ApplicationActions.HideSideMenu)
  public hideSideMenu(ctx: StateContext<ApplicationStateModel>) {
    ctx.patchState({ sideMenuVisibility: false });
  }

  private setDefaultLanguage(ctx: StateContext<ApplicationStateModel>): void {
    this.translateService.setDefaultLang(ApplicationConfig.defaultLanguage);
    const language: LanguageVersion = ctx.getState().language || ApplicationConfig.defaultLanguage;

    ctx.dispatch(new ApplicationActions.ChangeLanguage(language));
  }
}