import { Component, Input } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { AuthActions } from 'app/store/auth/auth.actions';
import { Observable } from 'rxjs';
import { RouterNavigation } from '@ngxs/router-plugin';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccountDTO } from '@data/auth/AccountDTO';
import { AuthSelectors } from 'app/store/auth/auth.selectors';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: [ './top-bar.component.scss' ]
})
export class TopBarComponent {
  @Select(AuthSelectors.getCurrentLogged)
  user$: Observable<AccountDTO>;

  @Input() public showSidebar: boolean = true;

  public collapsed: boolean = true;

  constructor(private store: Store, private actions: Actions) {
    this.actions.pipe(
      ofActionDispatched(RouterNavigation),
      takeUntilDestroyed()
    ).subscribe(() => this.collapsed = true);
  }

  public logout(): void {
    this.store.dispatch(new AuthActions.Logout());
  }

  public toggle(): void {
    const collapsed: boolean = !this.collapsed;
    this.collapsed = collapsed;
  }
}
