import { CanMatchFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthSelectors } from 'app/store/auth/auth.selectors';
import { AccountType } from '../data/auth/AccountType';

export const authUserTypeMatchGuard = (expectedAccountType: AccountType): CanMatchFn => {
  return (route, segments) => {
    const store = inject(Store);
    const accountType = store.selectSnapshot(AuthSelectors.type);
    return accountType === expectedAccountType;
  };
};